<template>
  <div>
    <q-card flat class="q-ma-md">
      <q-card-section class="q-pt-none row q-col-gutter-md">
        <div class="col-6">
          <q-select
            v-model="params.channelSelected"
            :options="params.channelsFiltered"
            label="Canal"
            option-label="name"
            option-value="id"
            clear-icon="mdi-close"
            clearable
            use-input
            outlined
            input-debounce="0"
            @filter="filterChannels"
          />
        </div>
        <div class="col-6">
          <q-select
            v-model="params.campaignSelected"
            :options="params.campaignsFiltered"
            label="Campanha"
            option-label="name"
            option-value="id"
            clear-icon="mdi-close"
            clearable
            use-input
            outlined
            input-debounce="0"
            @filter="filterCampaigns"
          />
        </div>
        <div class="col-12 text-right">
          <q-btn color="primary" :label="$t('clear')" no-caps outline class="q-px-md q-mx-md" @click="clearFilter()" />
          <q-btn
            color="positive"
            :label="$t('search')"
            no-caps
            unelevated
            class="q-px-md"
            @click="getChannelsPending()"
          />
        </div>
      </q-card-section>
    </q-card>
    <div>
      <q-table
        :data="dataTable.data"
        :columns="columns"
        :row-key="generateRowKey"
        :selected.sync="itemSelect"
        selection="multiple"
        no-data-label="Não existem canais pendentes de aprovação"
        :pagination="pagination"
      >
        <template v-slot:header-selection>
          <span>Selecionar</span>
        </template>

        <template v-slot:top>
          <q-toolbar>
            <q-btn
              unelevated
              label="Aprovar"
              round
              flat
              icon="mdi-thumb-up"
              color="positive"
              no-caps
              class="q-pl-sm q-pr-lg"
              @click="onApproveChannels(itemSelect)"
              :disabled="itemSelect.length == 0"
            >
              <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                Aprovar Canais Selecionados
              </q-tooltip>
            </q-btn>

            <q-btn
              unelevated
              label="Reprovar"
              round
              flat
              icon="mdi-thumb-down-outline"
              color="negative"
              no-caps
              class="q-px-lg"
              @click="onRepproveChannels(itemSelect)"
              :disabled="itemSelect.length == 0"
            >
              <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                Reprovar Canais Selecionados
              </q-tooltip>
            </q-btn>
          </q-toolbar>
        </template>

        <template v-slot:bottom>
          <q-toolbar class="bg-transparent" :class="!$q.dark.isActive ? 'text-dark' : 'text-white'">
            Total: {{ dataTable.total }}
            <q-space />
            <q-pagination
              unelevated
              v-model="currentPage"
              color="primary"
              :max="dataTable.last_page"
              :max-pages="6"
              boundary-numbers
              direction-links
            />
          </q-toolbar>
        </template>
      </q-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      channelsList: [],
      campaignsList: [],
      itemSelect: [],
      params: {
        channelSelected: null,
        channelsFiltered: [],
        campaignSelected: null,
        campaignsFiltered: []
      },

      dataTable: [],

      columns: [
        {
          name: 'site_id',
          label: 'Id do Canal',
          field: 'site_id',
          align: 'left',
          sortable: true
        },
        {
          name: 'site_name',
          label: 'Nome do Canal',
          field: 'site_name',
          align: 'left',
          sortable: true
        },
        {
          name: 'vertical',
          label: 'Vertical',
          field: 'site_type',
          align: 'left'
        },
        {
          name: 'campaign_id',
          label: 'Id da Campanha',
          field: 'campaign_id',
          align: 'left',
          sortable: true
        },
        {
          name: 'campaign_name',
          label: 'Nome da Campanha',
          field: 'campaign_name',
          align: 'left',
          sortable: true
        }
      ],
      pagination: {
        sortBy: 'site_id',
        rowsPerPage: 10
      }
    };
  },

  created() {
    this.getChannels();
    this.getCampaigns();
    this.getChannelsPending();
  },

  computed: {
    currentPage: {
      get() {
        return this.dataTable.current_page;
      },
      set(value) {
        if (value !== this.dataTable.current_page) this.getChannelsPending(value, this.pagination.rowsPerPage);
      }
    }
  },

  methods: {
    onApproveChannels(channels, alterStatus = 1) {
      this.$q
        .dialog({
          title: 'Atenção<em>!</em>',
          message: 'Deseja <b>APROVAR</b> os canais selecionados?',
          cancel: true,
          ok: { color: 'positive', label: 'Sim' },
          persistent: true,
          html: true
        })
        .onOk(async () => {
          this.onLoading(true);
          try {
            const { data, status } = await this.$http.post('/v2/redirect', {
              app: 'ADVERTISER',
              path: '/api/edit/campaign/channels_selecteds',
              params: {
                data: {
                  ids: channels.map((item) => item.campaign_site_id),
                  status: alterStatus
                }
              }
            });
            if (status === 200) {
              this.successNotify(data.message);
              this.dataTable.data = this.dataTable.data.filter(
                (item) => !channels.some((channel) => channel.campaign_site_id === item.campaign_site_id)
              );
              this.itemSelect = [];
            }
          } catch (error) {
            if (error.response && error.response.status === 500)
              this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
            else this.errorNotify(error);
          } finally {
            this.onLoading(false);
          }
        });
    },

    onRepproveChannels(channels, alterStatus = 3) {
      this.$q
        .dialog({
          title: 'Atenção<em>!</em>',
          message: 'Deseja <b>REPROVAR</b> os canais selecionados?',
          ok: { color: 'positive', label: 'Sim' },
          cancel: true,
          persistent: true,
          html: true
        })
        .onOk(async () => {
          this.onLoading(true);
          try {
            const { data, status } = await this.$http.post('/v2/redirect', {
              app: 'ADVERTISER',
              path: '/api/edit/campaign/channels_selecteds',
              params: {
                data: {
                  ids: channels.map((item) => item.campaign_site_id),
                  status: alterStatus
                }
              }
            });
            if (status === 200) {
              this.successNotify(data.message);
              this.dataTable.data = this.dataTable.data.filter(
                (item) => !channels.some((channel) => channel.campaign_site_id === item.campaign_site_id)
              );
              this.itemSelect = [];
            }
          } catch (error) {
            if (error.response && error.response.status === 500)
              this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
            else this.errorNotify(error);
          } finally {
            this.onLoading(false);
          }
        });
    },

    generateRowKey(row) {
      return row.site_id + '-' + row.campaign_id;
    },

    clearFilter() {
      for (let i in this.params) this.params[i] = Array.isArray(this.params[i]) ? [] : null;
      this.getChannelsPending();
    },

    async getChannels() {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get(`/redirect?app=AFFILIATE&path=/api/affiliate/site/filter`);
        if (status === 200)
          this.channelsList = data.map((item) => ({
            id: item.id,
            name: `${item.name} - (id: ${item.id})`
          }));
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getCampaigns() {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get(`redirect?app=ADVERTISER&path=/api/get/campaign/all`);
        if (status === 200)
          this.campaignsList = data.map((item) => ({
            id: item.id,
            name: `${item.name} - (id: ${item.id})`
          }));
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getChannelsPending(page = 1, perPage = 10) {
      this.onLoading(true);

      let query = `page=${page}%26perPage=${perPage}`;
      if (this.params.channelSelected) query += `%26channel_id=${this.params.channelSelected.id}`;
      if (this.params.campaignSelected) query += `%26campaign_id=${this.params.campaignSelected.id}`;

      try {
        const { data, status } = await this.$http.get(
          `/redirect?app=ADVERTISER&path=/api/get/campaign/site/pending/list?${query}`
        );
        if (status === 200) this.dataTable = data;
      } catch (error) {
        console.error(error);
      } finally {
        this.onLoading(false);
      }
    },

    filterChannels(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        if (!needle) this.params.channelsFiltered = this.channelsList;
        this.params.channelsFiltered = this.channelsList.filter((v) => v.name.toLocaleLowerCase().indexOf(needle) > -1);
      });
    },

    filterCampaigns(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        if (!needle) this.params.campaignsFiltered = this.campaignsList;
        this.params.campaignsFiltered = this.campaignsList.filter(
          (v) => v.name.toLocaleLowerCase().indexOf(needle) > -1
        );
      });
    }
  }
};
</script>
