import { render, staticRenderFns } from "./FormApproveSites.vue?vue&type=template&id=5e3228f4"
import script from "./FormApproveSites.vue?vue&type=script&lang=js"
export * from "./FormApproveSites.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QPagination from 'quasar/src/components/pagination/QPagination.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QCardSection,QSelect,QBtn,QTable,QToolbar,QTooltip,QSpace,QPagination});
